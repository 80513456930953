import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "emotion-theming"

import Header from "../components/header"
import Footer from "../components/footer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata{
            title
            twitter_handle
            theme {
              light_bg
              tint
              primary
              accent
              dark_bg
              center_width_percent
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <ThemeProvider theme={data.site.siteMetadata.theme}>
            <Header />
            <main>{children}</main>
            <Footer />
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
