import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45em` }}>
      <Logo />
    </div>
    <p>Please go back to the <Link to="/">main page</Link></p>
  </Layout>
)

export default NotFoundPage
