import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Brand from "../components/brand"

const Header = ({ siteTitle }) => (
  <header>
    <div
      css={theme => ({
        margin: '50px auto 20px auto',
        maxWidth: theme.center_width_percent + '%'
      })}
    >
      <Link
        to="/"
      >
        <div css={{ maxWidth: `200px`}}>
          <Brand />
        </div>
      </Link>
      <p css={{ marginBottom: `0.25em` }}><em>Intelligent marketing at any scale</em></p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
