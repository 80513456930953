import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "../components/logo"

const Footer = ({ siteTitle }) => (
  <footer>
    <div css={theme => ({
      margin: '5px auto',
      maxWidth: theme.center_width_percent + '%'
      })}
    >
      <p css={{ marginBottom: `0` }}> Proudly based in St. Petersburg, FL</p>
      © {new Date().getFullYear()}, 
      {` `}
      Meshsem, Inc.
      <div css={{ maxWidth: `40px` }}>
        <Logo />
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
